$(document).ready(function () {
  $(".cancel_subscription_btn").click(function () {
    let token = $(this).data('token');
    console.log(token);
    if (token) {
      cancelSubscription(token);
    }
  });
});

var cancelSubscription = function (token) {
    $.ajax({
        method: "DELETE",
        url: "/subscription/cancel",
        data: {token:token},
      }).done(function (msg) {
        console.log(msg);
      });
};
